/* INTER */

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter/Inter-Thin.ttf);
  font-weight: 100;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter/Inter-ExtraLight.ttf);
  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter/Inter-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter/Inter-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter/Inter-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter/Inter-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter/Inter-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter/Inter-ExtraBold.ttf);
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter/Inter-Black.ttf);
  font-weight: 900;
}

/* ALFA */

@font-face {
  font-family: Alfa;
  src: url(./assets/fonts/Alfa/AlfaSlabOne-Regular.ttf);
  font-weight: 400;
}

/* BEBAS */

@font-face {
  font-family: Bebas;
  src: url(./assets/fonts/Bebas/BebasNeue-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Bebas;
  src: url(./assets/fonts/Bebas/BebasNeue-Bold.otf);
  font-weight: 700;
}

/* OPEN SANS */

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/Open Sans/OpenSans-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/Open Sans/OpenSans-Italic.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/Open Sans/OpenSans-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/Open Sans/OpenSans-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}

/* ROBOTO */

@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto/Roboto-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto/Roboto-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto/Roboto-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto/Roboto-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/fonts/Roboto/Roboto-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

/* SOURCE SERIF */

@font-face {
  font-family: "Source Serif 4";
  src: url("./assets/fonts/Source Serif 4/SourceSerif4-VariableFont_opsz,wght.ttf");
}

@font-face {
  font-family: "Source Serif 4";
  src: url("./assets/fonts/Source Serif 4/SourceSerif4-Italic-VariableFont_opsz,wght.ttf");
  font-style: italic;
}

:root {
  --doc-height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: .25rem;
}

*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar-thumb {
  background: #A8A7CC;
  padding: 0 .125rem;
}

.classroom-page-root, .classroom-page-root > .MuiBackdrop-root {
  top: 4.7rem !important;
}

.order-by-container {
  margin-top: 2.5rem !important;
}

.order-by-menu {
  border-radius: 0;
  border: 1px solid #A8A7CC;
  padding: 0.125rem !important;
  width: 7.8125rem;
}

.order-by-menu li {
  font-family: Inter;
  font-size: 0.875rem;
}

